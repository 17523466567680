import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { IProductSchema } from './model';

const ProductSchema: FC<IProductSchema> = ({ name, brand, url, description, image }) => {
  const jsonLdSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Product',
      name,
      brand: {
        '@type': 'Brand',
        name: brand,
      },
      url,
      description,
      image,
    }),
  };

  return <Helmet script={[jsonLdSchema]} />;
};

export default ProductSchema;

import { isBrowser } from './browser';
import { LOCAL_STORAGE_PRODUCTS_KEY, RECENT_PRODUCTS_LENGTH } from './constants';

const getLSProducts = (lang: string, totalCount?: number): any[] => {
  if (!isBrowser()) {
    return [];
  }
  const recentProductsStr = localStorage.getItem(`${lang}_${LOCAL_STORAGE_PRODUCTS_KEY}`) || '[]';

  return JSON.parse(recentProductsStr).slice(0, totalCount);
};

const updateLSProducts = (
  lang: string,
  recentProduct: ProductCard.IProductCard,
  curProductSku?: string
) => {
  const curProductIndex = getLSProducts(lang).findIndex((item) => item.sku === curProductSku);

  const productsList =
    curProductIndex === -1
      ? [recentProduct, ...getLSProducts(lang, RECENT_PRODUCTS_LENGTH)]
      : [
          recentProduct,
          ...getLSProducts(lang, RECENT_PRODUCTS_LENGTH + 1).filter(
            (_item, i) => i !== curProductIndex
          ),
        ];
  localStorage.setItem(`${lang}_${LOCAL_STORAGE_PRODUCTS_KEY}`, JSON.stringify(productsList));
};

export { updateLSProducts, getLSProducts };

/* eslint-disable import/prefer-default-export */
export const responsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    partialVisibilityGutter: 43,
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1023, min: 768 },
    partialVisibilityGutter: 60,
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 576 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  smMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
    partialVisibilityGutter: 51,
  },
};

import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import useClickOutside from 'hooks/useClickOutside';

import { IVariantSelectorItem } from './model';
import './VariantSelector.scss';

interface IPropsVariantSelector {
  variants: IVariantSelectorItem[];
  changeActiveProduct: (string) => any;
  selectorIcon?: AppImages.IUniversalImageData;
}

const VariantSelector: FC<IPropsVariantSelector> = ({
  variants,
  changeActiveProduct,
  selectorIcon,
}): ReactElement | null => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const handleChangeDropdownState = useCallback(() => {
    setDropdownOpen((oldValue: boolean) => !oldValue);
  }, []);

  const handleDropdownClose = useCallback(() => {
    if (!isDropdownOpen) {
      return;
    }
    setDropdownOpen(false);
  }, [isDropdownOpen]);

  useClickOutside(wrapperRef, handleDropdownClose);

  if (!variants.length) {
    return null;
  }

  const currentVariant =
    variants.find((item: IVariantSelectorItem) => item.isActive) || variants[0];

  const handleChangeActiveProduct = useCallback(
    (sku) => {
      return () => {
        handleChangeDropdownState();
        changeActiveProduct(sku);
      };
    },
    [currentVariant.variantSku]
  );

  const handleOnBlur = useCallback((event: React.FocusEvent<HTMLUListElement | any>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleChangeDropdownState();
    }
  }, []);

  const dropdownClasses = classNames('custom-dropdown-menu', {
    show: 'show',
  });

  const selectorClasses = classNames('nf-variant-selector dropdown', {
    open: isDropdownOpen,
  });

  return (
    <div className={selectorClasses} data-test="VariantSelector" ref={wrapperRef}>
      <button
        id="dropdown-basic"
        className="dropdown-toggle btn btn-primary"
        onClick={handleChangeDropdownState}
        type="button"
        aria-label={currentVariant.variantDropdownValue}
      >
        {selectorIcon ? (
          <span className="dropdown-icon">
            <UniversalImage img={selectorIcon} />
          </span>
        ) : null}
        {currentVariant?.variantDropdownValue}
      </button>

      {variants.length > 1 && isDropdownOpen ? (
        <ul className={dropdownClasses} onBlur={handleOnBlur}>
          {variants.map((item: IVariantSelectorItem) => (
            <li
              key={item.variantSku}
              role="presentation"
              onClick={
                item.variantSku !== currentVariant.variantSku
                  ? handleChangeActiveProduct(item.variantSku)
                  : undefined
              }
              className="dropdown-item"
            >
              <button
                className={classNames('dropdown-item__option', {
                  active: item.isActive,
                })}
                type="button"
                aria-label={`${item.variantDropdownValue}`}
              >
                {item.yourPharmacyUrl ? (
                  <a href={item.yourPharmacyUrl[0]?.url}>{item.variantDropdownValue}</a>
                ) : (
                  ''
                )}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default VariantSelector;

import { TABLET_BREAKPOINT } from 'utils/constants';

export const DefaultItemWidth = 276;
export const DefaultItemHeight = 130 + 8;
export const WindowPadding = 50;
export const MobileViewRelatedElements = TABLET_BREAKPOINT;
export const MapStoresInfoBlockId = 'productIntroMapStoresInfoBlock';
export const MapStoresImageWrapperId = 'productIntroMapStoresImageWrapper';
export const MapStoresTriggerId = 'productIntroMapStoresTrigger';
export const RelatedElementsTriggerId = 'productIntroRelatedElementsTrigger';

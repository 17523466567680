import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';
import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';

import { AdditionalMessageProps } from './model';
import './AdditionalMessage.scss';

const AdditionalMessage: FC<AdditionalMessageProps> = ({ text }) => (
  <Container fluid className="additional-message">
    <DangerouslySetInnerHTML className="additional-message__text" html={text} />
  </Container>
);

export default AdditionalMessage;

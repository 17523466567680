import React, { FC, ReactElement, useCallback, useState, lazy, Suspense } from 'react';

import useAlgoliaResponse from 'hooks/useAlgoliaQuizResponse';

import StartQuizSection from 'components/StartQuizSection';

import { IPropsProductsFinder } from './model';
import './ProductsFinder.scss';

export const AlgoliaQuizLoadable = lazy(() => import('components/AlgoliaQuiz'));
const renderLoader = () => null;

const ProductsFinder: FC<IPropsProductsFinder> = ({
  startQuiz,
  ariaLabelPrev,
  ariaLabelNext,
  productQuizData,
  lang,
  setParentSearchState,
  callbackHandleQuizVisibility,
  handleParentAlgoliaFiltersUsed,
  handleCompleteProductsFinder,
}): ReactElement | null => {
  const [isVisibleAlgoliaQuiz, setVisibleAlgoliaQuiz] = useState(false);
  const {
    saveAlgoliaHitsResponse: saveAlgoliaHitsResponseQuiz,
    handleAlgoliaFiltersUsed: handleAlgoliaFiltersUsedQuiz,
    handleHitsResponseActivated: handleHitsResponseActivatedQuiz,
    isHitsResponseActivated: isHitsResponseActivatedQuiz,
  } = useAlgoliaResponse([]);

  const handleQuizVisibility = useCallback(
    (value: boolean) => {
      setVisibleAlgoliaQuiz(value);
      if (callbackHandleQuizVisibility) {
        callbackHandleQuizVisibility(value);
      }
    },
    [callbackHandleQuizVisibility]
  );

  const handleAlgoliaFiltersUsed = useCallback(
    (value: boolean) => {
      handleAlgoliaFiltersUsedQuiz(value);
      if (handleParentAlgoliaFiltersUsed) {
        handleParentAlgoliaFiltersUsed(value);
      }
    },
    [handleParentAlgoliaFiltersUsed]
  );

  return startQuiz ? (
    <div data-test="ProductsFinder" className="products-finder__container">
      <StartQuizSection startQuiz={startQuiz} handleQuizVisibility={handleQuizVisibility} />
      {isVisibleAlgoliaQuiz ? (
        <Suspense fallback={renderLoader()}>
          <AlgoliaQuizLoadable
            indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-productQuiz`}
            productQuizData={productQuizData}
            saveAlgoliaHitsResponse={saveAlgoliaHitsResponseQuiz}
            handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleHitsResponseActivated={handleHitsResponseActivatedQuiz}
            isHitsResponseActivated={isHitsResponseActivatedQuiz}
            lang={lang}
            ariaLabelPrev={ariaLabelPrev}
            ariaLabelNext={ariaLabelNext}
            handleQuizVisibility={handleQuizVisibility}
            setParentSearchState={setParentSearchState}
            handleCompleteProductsFinder={handleCompleteProductsFinder}
          />
        </Suspense>
      ) : null}
    </div>
  ) : null;
};

export default ProductsFinder;

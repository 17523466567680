import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

const ProductFeatureItem: FC<ProductTypes.TFeature> = ({ icon, featureTitle, label }) => (
  <li data-test="ProductFeatureItem">
    <div className="nf-product-feature-list__icon">
      <DangerouslySetInnerHtml html={icon?.svg?.content} />
    </div>
    <div className="nf-product-feature-list__text">
      <DangerouslySetInnerHtml html={label} className="nf-product-feature-list__symptom-title" />
      <DangerouslySetInnerHtml html={featureTitle} className="nf-product-feature-list__symptom" />
    </div>
  </li>
);

export default ProductFeatureItem;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import RelatedElement from 'components/RelatedElement';

import './RelatedElementsList.scss';
import { IPropsRelatedElementsList } from './model';

const RelatedElementsList: FC<IPropsRelatedElementsList> = ({
  items,
  title,
  CTABtn,
  submenuItemAnchor,
  retailerClickHandler,
}) => {
  return (
    <div
      data-test="RelatedElementsList"
      className="related-elements-list"
      {...(submenuItemAnchor ? { id: submenuItemAnchor } : {})}
    >
      <Container fluid>
        {title ? (
          <h2 className="related-elements-list-title" id="related-elements-list-title">
            {title}
          </h2>
        ) : null}
        {items?.length ? (
          <div className="related-elements-list-wrapper" id="related-elements-list-wrapper">
            {items.map(
              ({
                properties: {
                  relatedElementImage,
                  relatedElementTitle,
                  relatedElementUrl,
                  relatedElementImageAlt,
                },
              }) => (
                <RelatedElement
                  key={relatedElementTitle}
                  title={relatedElementTitle}
                  image={relatedElementImage}
                  imageAlt={relatedElementImageAlt}
                  url={relatedElementUrl?.[0]?.url || '/'}
                  name={relatedElementUrl?.[0]?.name || ''}
                  btn={CTABtn}
                  retailerClickHandler={retailerClickHandler}
                />
              )
            )}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedElementsList on IRelatedElementsList {
    relatedElementsListTitle
    relatedElementsListCTAButton {
      properties {
        ...FragmentNFButton
      }
    }
    relatedElementsListItems {
      properties {
        ...FragmentRelatedElement
      }
    }
    relatedElementsListSubmenuLink {
      ...FragmentSubmenuLink
    }
    relatedElementsListCTACloseButtonAriaLabel
    relatedElementsListCTACloseButtonTitle
  }
`;

export default RelatedElementsList;

import React, { FC, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import NFButton from 'components/common/NFButton';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import IconCustom from 'components/common/IconCustom';

import './MapStores.scss';
import { IPropsMapStores } from './model';

const MapStores: FC<IPropsMapStores> = ({
  title,
  text,
  CTABtn,
  clearButtonAriaLabel,
  searchTarget,
  searchMapUrl,
  submenuItemAnchor,
  searchInputTitle,
  mapImageAlt,
  mapImage,
  infoBlockId,
  imageWrapperId,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleClearValue = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <div
      data-test="MapStores"
      className="map-stores"
      {...(submenuItemAnchor ? { id: submenuItemAnchor } : {})}
    >
      <div className="map-stores-wrapper">
        <Container fluid>
          <div className="map-stores-panel">
            <div className="map-stores-info-block" {...(infoBlockId ? { id: infoBlockId } : {})}>
              <h2 className="map-stores-title">{title}</h2>
              {text ? <DangerouslySetInnerHtml html={text} className="map-stores-text" /> : null}
              <p className="map-stores-input-title">{searchInputTitle}</p>
              <Form
                target="_blank"
                action={`${searchMapUrl}${searchTarget}+${searchValue}`}
                className="form"
              >
                <div className="search-input-holder">
                  <Form.Label srOnly htmlFor="mapControl">
                    {searchInputTitle}
                  </Form.Label>
                  <Form.Control
                    id="mapControl"
                    type="text"
                    className="search-input"
                    value={searchValue}
                    onChange={handleChange}
                  />
                  {searchValue ? (
                    <button
                      type="button"
                      aria-label={clearButtonAriaLabel}
                      className="map-stores-clear-btn"
                      onClick={handleClearValue}
                    >
                      <IconCustom icon={`close_icon-2 `} />
                    </button>
                  ) : null}
                </div>

                {CTABtn ? (
                  <NFButton {...CTABtn} type="submit" className="map-stores-submit-btn" />
                ) : null}
              </Form>
            </div>
          </div>
        </Container>
        <div
          className="map-stores-image-wrapper"
          {...(imageWrapperId ? { id: imageWrapperId } : {})}
        >
          {mapImage ? (
            <UniversalImage
              imageAlt={mapImageAlt}
              img={mapImage}
              wrapperClassName="map-stores-image"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentMapStores on IMapStores {
    mapStoresTitle
    mapStoresText
    mapStoresSearchTarget
    mapStoresCTAClearButtonAriaLabel
    mapStoresCTACloseButtonTitle
    mapStoresCTACloseButtonAriaLabel
    mapStoresSearchInputTitle
    mapStoresMapImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    mapStoresMapImageAlt
    mapStoresSearchMapUrl
    mapStoresCTASearchButton {
      properties {
        ...FragmentNFButton
      }
    }
    mapStoresSubmenuLink {
      ...FragmentSubmenuLink
    }
  }
`;

export default MapStores;

import React, { FC } from 'react';
import UniversalImage from 'components/common/UniversalImage';

interface IProductDosageCalcFieldProps {
  icon: AppImages.IUniversalImageData;
  value: string | null;
}

const ProductDosageCalcField: FC<IProductDosageCalcFieldProps> = ({ icon, value }) => (
  <p className="dosage-calc__field">
    <span>
      <UniversalImage img={icon} />
    </span>
    {value || null}
  </p>
);

export default ProductDosageCalcField;

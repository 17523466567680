import React, { FC } from 'react';
import classNames from 'classnames';
import UniversalImage from 'components/common/UniversalImage';

import { IProductImageGlowEffectComponentProps } from './model';

import './ProductImageGlowEffect.scss';

const ProductImageGlowEffect: FC<IProductImageGlowEffectComponentProps> = ({
  img,
  imgAlt,
  maxMobileImageHeight,
}) => {
  const imgFluid = img?.gatsbyImage?.childImageSharp?.fluid;

  const classList = classNames('nf-product-banner__product-image', {
    'holder-vertical-img': imgFluid && imgFluid?.aspectRatio < 1,
    'holder-horizontal-img': imgFluid && imgFluid?.aspectRatio >= 1,
    'holder-quadrant-horizontal-img':
      imgFluid && imgFluid?.aspectRatio >= 1 && imgFluid?.aspectRatio < 1.5,
    'holder-quadrant-vertical-img':
      imgFluid && imgFluid?.aspectRatio < 1 && imgFluid?.aspectRatio > 0.7,
  });

  return (
    <div className={classList}>
      <div className="nf-product-banner__image-frame" style={{ minHeight: maxMobileImageHeight }}>
        {img && (
          <UniversalImage
            img={img}
            imageAlt={imgAlt}
            isLazyLoading={false}
            objectFitData={{ objectFit: 'contain' }}
            withDirectionClassName
          />
        )}
      </div>
    </div>
  );
};

export default ProductImageGlowEffect;

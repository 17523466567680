import React, { FC, useCallback, useRef } from 'react';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';

import IconCustom from 'components/common/IconCustom';

import './SimplePopup.scss';
import { IPropsSimplePopup } from './model';

const SimplePopup: FC<IPropsSimplePopup> = ({
  id,
  closeButtonTitle,
  closeButtonAriaLabel,
  withCloseBtn,
  handleVisibilityChange,
  children,
  className,
  clickOutsideExceptionsIds,
}) => {
  const wrapperRef = useRef(null);

  const handleClosePopup = useCallback(() => {
    if (!handleVisibilityChange) {
      return;
    }
    handleVisibilityChange();
  }, [handleVisibilityChange]);

  useClickOutside(wrapperRef, handleClosePopup, clickOutsideExceptionsIds);

  return (
    <div
      data-test="SimplePopup"
      className={classNames('simple-popup', {
        [className || '']: className,
      })}
      {...(id ? { id } : {})}
      ref={wrapperRef}
    >
      <div className="simple-popup-wrapper">
        {withCloseBtn ? (
          <button
            type="button"
            aria-label={closeButtonAriaLabel}
            className="simple-popup-close-mobile-btn"
            onClick={handleVisibilityChange}
          >
            <IconCustom icon={`close_icon-2 `} />
          </button>
        ) : null}
        <div className="simple-popup-content" id="simple-popup-content">
          {children}
        </div>

        {withCloseBtn ? (
          <div className="simple-popup-action-block" id="simple-popup-action-block">
            {closeButtonTitle && closeButtonAriaLabel ? (
              <button
                type="button"
                aria-label={closeButtonAriaLabel}
                className="simple-popup-close-btn"
                onClick={handleVisibilityChange}
              >
                <IconCustom icon={`close_icon-2 `} />
                {closeButtonTitle}
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SimplePopup;

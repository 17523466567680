import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import NFButton from 'components/common/NFButton';

import { IPropsRelatedElement } from './model';
import './RelatedElement.scss';

const RelatedElement: FC<IPropsRelatedElement> = ({
  image,
  title,
  url,
  name,
  btn,
  imageAlt,
  retailerClickHandler,
}) => {
  const handleRetailerClick = useCallback(() => {
    if (typeof retailerClickHandler !== 'function') return;
    retailerClickHandler(name);
  }, []);

  return (
    <div data-test="RelatedElement" className="related-element">
      <a
        className="related-element-holder-link"
        href={url}
        rel="noreferrer"
        aria-label={title}
        target="_blank"
        onClick={handleRetailerClick}
      >
        {(image.mobileImage || image.gatsbyImage || image.svg?.content) && (
          <div className="related-element-logo">
            <UniversalImage
              img={image}
              imageAlt={imageAlt}
              objectFitData={{ objectFit: 'contain' }}
              wrapperClassName="related-element-image"
            />
          </div>
        )}
        <div className="related-element-info">
          <p className="related-element-title">{title}</p>
          {btn ? <NFButton {...btn} /> : null}
        </div>
      </a>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedElement on IRelatedElementData {
    relatedElementTitle
    relatedElementImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 150, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 150, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      svg {
        content
      }
    }
    relatedElementImageAlt
    relatedElementUrl {
      url
      name
    }
  }
`;

export default RelatedElement;

import { IResponsiveObj } from 'components/ControlledCarousel/model';

const responsiveObj: IResponsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 860 },
    items: 3,
  },
  smallTablet: {
    breakpoint: { max: 860, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export default responsiveObj;

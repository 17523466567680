import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

import RelatedElement from 'components/RelatedElement';
import ControlledCarousel from 'components/ControlledCarousel';

import './RelatedElementsCarousel.scss';
import { IPropsRelatedElementsCarousel } from './model';
import responsiveObj from './constants';

const RelatedElementsCarousel: FC<IPropsRelatedElementsCarousel> = ({
  items,
  title,
  CTABtn,
  submenuItemAnchor,
  responsiveObjCalculated,
  carouselControls,
  retailerClickHandler,
}) => {
  const [isInitiated, setInitiated] = useState<boolean>(false);

  useEffect(() => {
    responsiveObj.desktop.items = responsiveObjCalculated?.desktop;
    if (!isInitiated) {
      setInitiated(true);
    }
  }, [responsiveObjCalculated]);

  return isInitiated && carouselControls ? (
    <div
      data-test="RelatedElementsCarousel"
      className="related-elements-carousel"
      {...(submenuItemAnchor ? { id: submenuItemAnchor } : {})}
    >
      <Container fluid>
        <h2 className="related-elements-carousel-title">{title}</h2>
      </Container>
      <div className="related-elements-carousel-wrapper">
        {items?.length ? (
          <ControlledCarousel
            bgGradientColor={null}
            classes="related-elements-controlled-carousel"
            responsiveObj={responsiveObj}
            carouselControls={carouselControls}
          >
            {items.map(
              ({
                properties: {
                  relatedElementImage,
                  relatedElementTitle,
                  relatedElementUrl,
                  relatedElementImageAlt,
                },
              }) => (
                <RelatedElement
                  key={relatedElementTitle}
                  title={relatedElementTitle}
                  image={relatedElementImage}
                  imageAlt={relatedElementImageAlt}
                  url={relatedElementUrl?.[0]?.url || '/'}
                  name={relatedElementUrl?.[0]?.name || ''}
                  btn={CTABtn}
                  retailerClickHandler={retailerClickHandler}
                />
              )
            )}
          </ControlledCarousel>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default RelatedElementsCarousel;

import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';

import { IPropsStartQuizSection } from './models';
import './StartQuizSection.scss';
import './ShadowStartQuizSection.scss';

import NotepadIcon from './notepad-icon.svg';
import ArrowIcon from './arrow-icon.svg';

const StartQuizSection: FC<IPropsStartQuizSection> = ({ startQuiz, handleQuizVisibility }) => {
  const { startQuizBoldTitle, startQuizLightTitle, startQuizAriaLabel } = startQuiz;
  const handleOpen = useCallback(() => {
    handleQuizVisibility(true);
  }, []);

  return startQuizAriaLabel && startQuizLightTitle ? (
    <button
      className="start-quiz-section"
      data-test="StartQuizSection"
      type="button"
      aria-label={startQuizAriaLabel}
      onClick={handleOpen}
    >
      <span className="start-quiz-block">
        <img
          className="start-quiz-section__button--notepad"
          src={NotepadIcon}
          alt={startQuizAriaLabel}
        />
        <span className="start-quiz-section__span">
          <strong>{startQuizBoldTitle}</strong>
          <span>{startQuizLightTitle}</span>
        </span>
      </span>
      <span className="start-quiz-section__button">
        <img
          className="start-quiz-section__button--arrow"
          src={ArrowIcon}
          alt={startQuizAriaLabel}
        />
      </span>
    </button>
  ) : null;
};

export const query = graphql`
  fragment FragmentStartQuizSection on IStartQuiz {
    startQuizBoldTitle
    startQuizLightTitle
    startQuizAriaLabel
  }
`;

export default StartQuizSection;

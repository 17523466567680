import React, { FC } from 'react';

import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';

import { IProductBannerDescription } from './model';

const ProductBannerDescription: FC<IProductBannerDescription> = ({
  description,
}): React.ReactElement => (
  <DangerouslySetInnerHTML
    element="p"
    className="nf-product-banner__product-lead-text"
    html={description}
  />
);

export default ProductBannerDescription;

import React, { FC, useState, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import VariantSelector from 'components/VariantSelector';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import ProductDosageCalcField from './ProductDosageCalcField';

import { IProductDosageCalcComponentProps } from './model';
import 'components/ProductDosageCalc/ProductDosageCalc.scss';

const ProductDosageCalc: FC<IProductDosageCalcComponentProps> = ({ variants, labels }) => {
  const {
    pdpDosageCalculatorTitle: title,
    pdpDosageCalculatorDescription: description,
    pdpDosageCalculatorSelectorField: dropdown,
    pdpDosageCalculatorSingleDosageField: singleDosage,
    pdpDosageCalculatorMaxDosageField: maxDosage,
  } = labels;

  const [selectorVariants, setSelectorVariants] = useState([
    {
      variantDropdownValue: dropdown[0].properties.dosageCalcFieldName,
      variantSku: dropdown[0].properties.dosageCalcFieldName,
      productTitle: dropdown[0].properties.dosageCalcFieldName,
      isActive: true,
    },
    ...(variants?.map(({ dropdownOption }) => ({
      variantDropdownValue: dropdownOption,
      variantSku: dropdownOption,
      productTitle: dropdownOption,
      isActive: false,
    })) || []),
  ]);

  const changeActiveDropdownOption = useCallback((activeOption) => {
    setSelectorVariants(
      selectorVariants.map((variant) => ({
        variantDropdownValue: variant.variantDropdownValue,
        variantSku: variant.variantSku,
        productTitle: variant.productTitle,
        isActive: variant.variantDropdownValue === activeOption,
      }))
    );
  }, []);

  const getActiveFields = useCallback(
    (fieldName) => {
      const activeDropdownOption = selectorVariants.filter(({ isActive }) => isActive)[0];
      const { variantDropdownValue } = activeDropdownOption;
      let fieldValue = null;

      variants?.forEach((variant) => {
        if (variant.dropdownOption === variantDropdownValue) {
          fieldValue = variant[fieldName];
        }
      });

      return fieldValue;
    },
    [selectorVariants]
  );

  return (
    <div className="dosage-calc">
      <Container fluid>
        {title ? (
          <Row>
            <Col>
              <h5 className="dosage-calc__title">{title}</h5>
            </Col>
          </Row>
        ) : null}

        {description ? (
          <Row>
            <Col>
              <DangerouslySetInnerHtml html={description} className="dosage-calc__description" />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col lg={4} md={12}>
            <p className="dosage-calc__label">{dropdown[0].properties.dosageCalcFieldName}</p>
            <VariantSelector
              variants={selectorVariants}
              changeActiveProduct={changeActiveDropdownOption}
              selectorIcon={dropdown[0].properties.dosageCalcFieldIcon}
            />
          </Col>
          <Col lg={3} md={4}>
            <p className="dosage-calc__label">{singleDosage[0].properties.dosageCalcFieldName}</p>
            <ProductDosageCalcField
              value={getActiveFields('singleDosageValue')}
              icon={singleDosage[0].properties.dosageCalcFieldIcon}
            />
          </Col>
          <Col lg={5} md={8}>
            <p className="dosage-calc__label">{maxDosage[0].properties.dosageCalcFieldName}</p>
            <ProductDosageCalcField
              value={getActiveFields('maxDosageValue')}
              icon={maxDosage[0].properties.dosageCalcFieldIcon}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDosageCalc;

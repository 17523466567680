import { IPropsNFButtonData } from 'components/common/NFButton';

const getButtonStyles = (
  isVisible: boolean,
  btnData: IPropsNFButtonData
): IPropsNFButtonData['btnStyles'] | undefined => {
  return btnData?.btnStyles
    ? {
        ...btnData.btnStyles,
        ...(isVisible
          ? {
              inactiveBgColor: btnData.btnStyles.activeBgColor,
              inactiveBorderColor: btnData.btnStyles.activeBorderColor,
              inactiveTextColor: btnData.btnStyles.activeTextColor,
            }
          : {
              activeBgColor: btnData.btnStyles.activeBgColor,
              activeBorderColor: btnData.btnStyles.activeBorderColor,
              activeTextColor: btnData.btnStyles.activeTextColor,
            }),
      }
    : undefined;
};

const Helpers = {
  getButtonStyles,
};

export default Helpers;

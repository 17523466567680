import React, { FC } from 'react';

import ProductFeatureItem from './ProductFeatureItem';
import './ProductFeatureList.scss';

const ProductFeatureList: FC<{ featureList?: { properties: ProductTypes.TFeature }[] }> = ({
  featureList,
}) => (
  <ul className="nf-product-feature-list" data-test="ProductFeatureList">
    {featureList?.map(({ properties }) => (
      <ProductFeatureItem {...properties} />
    ))}
  </ul>
);

export default React.memo(ProductFeatureList);

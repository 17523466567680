import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import ProductsCarousel from 'components/ProductsCarousel';
import ProductCard from 'components/ProductCard';

import { getProductDescription } from 'utils/helpers';
import { gtmService } from 'services/gtmService';

import { responsiveObj } from './constants';
import { RecentlyViewedProductsProps } from './model';

import './RecentlyViewedProducts.scss';

const RecentlyViewedProducts: FC<RecentlyViewedProductsProps> = ({
  products,
  title,
  subtitle,
  btn,
  bgColor = 'white',
  fontColor,
  shopBtnShow = false,
  carouselControls,
  pageName,
}) => {
  useEffect(() => {
    const timeoutId = gtmService.emitProductListingView(pageName, products);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div data-test="RecentlyViewedProducts" className="recently-viewed-products-holder">
      {products?.length > 0 ? (
        <div className={`nf-popular-products ${bgColor}-default-bg`}>
          <Container className="container-RecentlyViewedProducts" fluid>
            <ProductsCarousel
              title={title}
              subtitle={subtitle}
              responsiveObj={responsiveObj}
              btn={btn}
              carouselClass="popular-product"
              fontColor={fontColor}
              bgColor={bgColor}
              {...carouselControls}
            >
              {products.map(
                ({
                  sku,
                  preferred,
                  descriptionLong,
                  descriptionShort,
                  defaultProductTitle,
                  defaultProductImage,
                  productFamily,
                  productLinks,
                  link,
                  productImageAlt,
                  productVariant,
                }) => (
                  <ProductCard
                    key={sku}
                    label={preferred?.[0]?.title}
                    labelBgColor={
                      productFamily?.[0]?.productListingBadgeBgColor?.[0]?.properties?.colorPicker
                        ?.label || DEFAULT_PRODUCT_FAMILY_COLOR
                    }
                    title={defaultProductTitle}
                    text={getProductDescription(descriptionLong, descriptionShort)}
                    img={defaultProductImage}
                    classes="nf-popular-product-card"
                    btn={productLinks?.[0]?.properties}
                    shopBtnShow={shopBtnShow}
                    link={link}
                    fontColor={fontColor}
                    alt={productImageAlt}
                    sku={sku}
                    preferred={preferred}
                    productVariant={productVariant}
                  />
                )
              )}
            </ProductsCarousel>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentRecentlyViewedProducts on IRecentlyViewedProducts {
    recentlyViewedTitle
    sectionBg {
      ...FragmentColorProps
    }
    shopBtnsShow
    sectionCtaBtn {
      properties {
        ...FragmentNFButton
      }
    }
    sectionFontColor {
      ...FragmentColorProps
    }
  }
`;

export default RecentlyViewedProducts;
